export const MenuItems = [
    {
        title: 'About',
        url: '#about',
        route:'/about',
        cName: 'nav-links'
    },
    {
        title: 'Projects',
        url: '#projects',
        route:'/projects',
        cName: 'nav-links'
    },
    {
        title: 'Resume',
        url: '#resume',
        route:'/resume',
        cName: 'nav-links'
    },
    {
        title: 'Contact',
        url: '#contact',
        route:'/contact',
        cName: 'nav-links'
    }
]