const AboutMe = () => {
    return (
        <div className='about-section' id='about'>
            <h2>About Me</h2>
            <p>
                Currently a <b>3rd year</b> undergraduate studying Computer Science in the <b>National University of Singapore (NUS)</b>, I aspire to be a front-end web developer who is able to create responsive web pages with beautiful UIs. 
            </p>
            <p></p>
        </div>
    )
}

export default AboutMe