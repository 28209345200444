import { ContactItems } from '../data/ContactItems'

const Contact = () => {
    return (
        <div className='contact-section' id='contact'>
            <h2>Contact Me</h2>
            <div>
                <p>Feel free to reach out to me through any of the links below!</p>
                
                <div className='contact-list'>
                    {ContactItems.map((contact, index) => {
                        return (
                            <div key={index}>
                                <a href={contact.url} target='_blank' rel="noreferrer">
                                    <i className={contact.icon}></i>
                                </a>
                            </div>
                        )
                    })}
                </div>
            </div>
            <div></div>
        </div>
    )
}

export default Contact