import { useState } from "react";
import Modal from 'react-modal'
const modalStyle = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        transform: 'translate(-50%, -50%)',
    },
}

const ProjectItemCard = ({ project }) => {
    const [modal, setModal] = useState(false);

    return (
        <div className="project-item">
            <div>
                <div>
                    <img onClick={() => setModal(true)} src={project.pictureUrl} alt={project.alt} className='project-image' />

                </div>
                <div className='project-description'>
                    <h4 onClick={() => setModal(true)}>
                        {project.title}
                    </h4>
                </div>
            </div>

            <Modal
                isOpen={modal}
                style={modalStyle}
                shouldCloseOnOverlayClick={true}
                onRequestClose={() => setModal(false)}
                >
                <div className='modal'>
                    <div className='modal-cross'>
                        <div></div>
                        <i className="fas fa-times" onClick={() => setModal(false)}></i>
                    </div>
                    <p></p>
                    <p>
                        {project.description}
                    </p>
                    <div className='techstack'>
                        {project.techstack.map((t, index) => {
                            return <i className={t} key={index}></i>
                        })}
                    </div>
                    <p></p>
                    <a className='modal-button' href={project.url} target='_blank' rel="noreferrer">
                        <i className="fab fa-github" />
                        {" "}GitHub
                    </a>
                    <p></p>
                </div>
            </Modal>

        </div>
    )
}

export default ProjectItemCard