const ContactItems = [
    {
        title: 'Github',
        url: 'https://www.github.com/natosy',
        icon: 'fab fa-github'
    }, {
        title: 'LinkedIn',
        url: 'https://www.linkedin.com/in/natosy',
        icon: 'fab fa-linkedin-in'
    }, 
    {
        title: 'Email',
        url: 'mailto:natashaongsy@gmail.com',
        icon: 'fas fa-envelope'
    }
]

export { ContactItems }