import { BrowserRouter as Router, Switch, Route, Link, Redirect } from "react-router-dom";
import React, { useState } from 'react'
import { MenuItems } from './data/MenuItems.js';

import './App.css';
import Intro from './components/Intro.js'
import AboutMe from './components/AboutMe'
import Projects from './components/Projects'
import Contact from './components/Contact';
import Resume from './components/Resume'

const NavigationBar = () => {

  const [clicked, setClicked] = useState(false)

  return (
    <nav className="navbar">
      <div className="navbar-logo" onClick={() => setClicked(false)}>
        <Link className="navbar-name" to='/' >
          natosy
        </Link>
      </div>
      <div className="menu-icon" onClick={() => setClicked(!clicked)}>
        <i className={clicked ? 'fas fa-times' : 'fas fa-bars'}></i>
      </div>
      <ul className={clicked ? 'nav-menu active' : 'nav-menu'}>
        {MenuItems.map((item, index) => {
          return (
            <li key={index} className='nav-li' onClick={() => setClicked(false)}>
              <Link className={item.cName} to={item.route}>
                {item.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </nav>
  )
}

const App = () => {
  return (
    <Router>
      <NavigationBar />
      <Switch>
        <Route exact path='/'>
          <Intro />
        </Route>
        <Route path='/about'>
          <AboutMe />
        </Route>
        <Route path='/projects'>
          <Projects />
        </Route>
        <Route path='/resume'>
          <Resume />
        </Route>
        <Route path='/contact'>
          <Contact />
        </Route>
        <Route>
          <Redirect to='/' />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
