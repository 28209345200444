export const ProjectItems = [
    {
        title: 'NUStudy',
        techstack: ['devicon-dart-plain', 'devicon-flutter-plain'],
        description: 'NUStudy is an app meant to allow students of National University of Singapore (NUS) to find peers taking similar modules. It allows for the efficient creation and scouting of mutually beneficial study sessions. Developed with Dart and Flutter.',
        pictureUrl: 'images/projects/nustudy.png',
        url: 'https://github.com/natosy/nustudy/',
        alt: 'NUStudy Logo'
    }, {
        title: 'BudgetBaby',
        techstack: ['devicon-java-plain', 'devicon-javafx-plain'],
        description: 'BudgetBaby is a budget tracker app targetted for univeristy students who wish to control their daily expenses. It allows users to add financial records and keep track of their expenditure throughout a month. Developed with Java and JavaFX.',
        pictureUrl: 'images/projects/budgetbaby.png',
        url: 'https://github.com/natosy/tp/',
        alt: 'BudgetBaby Logo'
    }, {
        title: 'Ronald',
        techstack: ['devicon-java-plain', 'devicon-javafx-plain'],
        description: 'Ronald is a task list application which helps users to keep track of todo, deadline and event items. Developed with Java and JavaFX.',
        pictureUrl: 'images/projects/duke.png',
        url: 'https://github.com/natosy/ip/',
        alt: 'Ronald Logo'
    }
]