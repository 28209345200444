import { Skills, Experience } from '../data/ResumeItems'


// const EducationItem = ({ education }) => {
//     return (
//         <div className='education-item'>
//             <a href={education.url}>
//                 <img src={education.pictureUrl} alt='' className='education-image' />
//             </a>
//             <div className='education-description'>
//                 <h5>{education.description}</h5>
//                 <h6>{education.title}</h6>
//                 <p>{education.timeline}</p>
//             </div>
//         </div>
//     )
// }

const SkillItem = ({ skill }) => {
    return (
        <div className='skills-item'>
            <i className={skill.class}></i>
            <p>{skill.name}</p>
        </div>
    )
}

// const CourseWorkItem = ({ course }) => {
//     return (
//         <div>
//             <div className='course-item'>{course.title}</div>
//         </div>
//     )
// }

const ExperienceItem = ({ exp }) => {
    return (
        <div className='experience-item'>
            <img src={exp.logo} alt={exp.company} className='experience-image' />
            <div className='experience-main'>
                <div className='experience-title'>{exp.role}</div>
                <div className='experience-title'>@ {exp.company}</div>
                <p>{exp.start} - {exp.end}</p>
                <p><b>Techstack</b></p>
                <div className='techstack'>
                    {exp.techstack.map((t, index) => {
                        return <i className={t} key={index}></i>
                    })}
                </div>
            </div>
        </div>
    )
}

const Resume = () => {
    return (
        <div className='resume-section' id='resume'>
            <h2>Resume</h2>
            <h4>Skills</h4>
            <div className='skills'>
                {Skills.map((skill, index) => {
                    return <SkillItem skill={skill} key={index} />
                })}
            </div>
            <h4>Experience</h4>
            <div className="experience">
                {Experience.map((exp, index) => {
                    return <ExperienceItem exp={exp} key={index} />
                })}
            </div>
            {/* <h4>Coursework</h4>
            <div className='coursework'>
                {CourseWork.map((course, index) => {
                    return <CourseWorkItem course={course} key={index} />
                })}
            </div> */}
            {/* <h4 className='education-header'>Education</h4>
            <div className='education-section'>
                {Education.map((education, index) => {
                    return <EducationItem education={education} index={index} />
                })}
            </div> */}
        </div>
    )
}

export default Resume