const Education = [
    {
        title: 'National University of Singapore (NUS)',
        timeline: 'Aug 2019 - May 2023 (Predicted)',
        description: 'Bachelor of Computing (Honours) in Computer Science',
        pictureUrl: 'photo_1.jpg'
    }, {
        title: 'Nanyang Junior College',
        timeline: 'Jan 2017 - Dec 2018',
        description: "GCE 'A' Levels",
        pictureUrl: 'photo_1.jpg'
    }
]

const Skills = [

    {
        name: 'CSS',
        class: "devicon-css3-plain"
    },
    {
        name: 'Dart',
        class: "devicon-dart-plain"
    },
    {
        name: 'Flutter',
        class: "devicon-flutter-plain"
    },
    {
        name: 'HTML',
        class: "devicon-html5-plain"
    },
    {
        name: 'Java',
        class: "devicon-java-plain"
    },
    {
        name: 'JavaScript',
        class: "devicon-javascript-plain"
    },
    {
        name: 'MongoDB',
        class: "devicon-mongodb-plain"
    },
    {
        name: 'PostgreSQL',
        class: "devicon-postgresql-plain"
    },
    {
        name: 'Python',
        class: "devicon-python-plain"
    },
    {
        name: 'React',
        class: "devicon-react-plain"
    }
]

const CourseWork = [
    {
        title: 'Programming Methodology I & II',
        grade: 'A-'
    }, {
        title: 'Data Structures and Algorithms',
        grade: 'A-'
    }, {
        title: 'Operating Systems',
        grade: 'A'
    }, {
        title: 'Linear Algebra',
        grade: 'B+'
    }, {
        title: 'Probability and Statistics',
        grade: 'A-'
    }, {
        title: 'Database Systems',
        grade: 'TBC'
    }, {
        title: 'Computer Networks',
        grade: 'TBC'
    }, {
        title: 'Information Security',
        grade: 'TBC'
    }, {
        title: 'Software Engineering',
        grade: 'TBC'
    },
]

const Experience = [
    {
        company: "SurerSG",
        role: "Frontend Web Developer Intern",
        start: "09/2021",
        end: "now",
        logo: "images/companies/sureredited.png",
        techstack: [
            'devicon-dotnetcore-plain', 'devicon-angularjs-plain'
        ]
    },
    {
        company: "MicroSec",
        role: "Full Stack Developer Intern",
        start: "05/2021",
        end: "09/2021",
        logo: "images/companies/microsecedited.png",
        techstack: [
            'devicon-python-plain', 'devicon-docker-plain', 'devicon-postgresql-plain', 'devicon-flask-plain', 'devicon-sqlalchemy-plain'
        ]
    }, 
]
export { Education, Skills, CourseWork, Experience }