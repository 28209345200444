import { ProjectItems } from '../data/ProjectItems'
import ProjectItemCard from './ProjectItemCard'
export const Projects = () => {
    return (
        <div className='project-section' id='projects'>
            <h2>My Projects</h2>
            <div className='projects'>
                {ProjectItems.map((item, index) => {
                    return (
                        <ProjectItemCard key={index} project={item} />
                    )
                })}
            </div>
            <footer></footer>
        </div>
    )
}

export default Projects