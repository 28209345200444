import Typewriter from 'typewriter-effect';

const Intro = () => {
    return (
        <div className="intro">
            <h1>
                <Typewriter
                    options={{
                        autoStart: true,
                        loop: true
                    }}
                    onInit={(typewriter) => {
                        typewriter
                            .typeString("Hi, I'm Natasha.")
                            .pauseFor(5000)
                            .start();
                    }}
                />
            </h1>
            <p>An aspiring front-end web developer</p>
        </div>
    )
}

export default Intro